<script>
  import * as Drift from '../../modules/drift.module'
  import * as User from '../../modules/users.module'
  import * as API from '../../modules/api.module'
  import { bank, user, rate, inspections, activities, accounts, transactions, quarters } from '../../modules/store.module'
  import { onMount } from 'svelte'
  import Header from '../header.svelte'
  import Questionnaire from '../questionnaire.svelte'
  import Quarters from '../quarters.svelte'
  import { getAccounts } from '../../modules/accounts.module'
  import { getTransactions } from '../../modules/transactions.module'
  import { updateQuarters } from '../../modules/time.module'

  onMount(async () => {
    $accounts = await getAccounts($bank)
    $inspections = await API.getInspections()
    $activities = await API.getActivities()
    const userFromBank = await User.getUserFromBank($bank)
    const userNumber = userFromBank.number
    if (!userNumber) return null
    const log = { event: 'dailyVisit', userNumber: `${userNumber}` }
    await API.addLog(log)
    $transactions = await getTransactions(userNumber, $bank, $accounts)
    $quarters = await updateQuarters($quarters, userNumber)
    const [userFromBitTax, userFromBitBase] = await Promise.all([API.getUser(userNumber, 'bittax'), API.getUser(userNumber, 'bitbase')])
    $user = { ...userFromBank, ...userFromBitBase, ...userFromBitTax }
    $user.inspection ??= { code: '' }
    $user.activity ??= { code: '' }
    $user.email ??= ''
    if (!userFromBitTax) API.saveUser($user)
    const latestDeclaration = await API.getLatestDeclaration($user.number)
    $rate = latestDeclaration ? latestDeclaration.rate : $user.type.code === 1 ? 'УСН' : 'ОСН'
    Drift.connect($user)
    gtag('set', 'user_properties', { number: $user.number, bank: $bank })
  })

</script>

<div class="container">
  <Header />
  <Questionnaire />
  <Quarters />
</div>

<style lang="scss">
  .container {
    padding: 0 16px;
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 32px;
  }
</style>