import { DateTime } from 'luxon'
import * as Time from './time.module'
import { get } from 'svelte/store'
import { parse } from 'cookie'
import * as Fetch from './fetch.module'

import * as Utilities from './utilities.module'
import * as Store from './store.module'

const baseURL = 'https://api.priorbank.by:9344'

const getAuthHeader = () => ({ 'Authorization': `Bearer ${parse(document.cookie).access_token}` })

const getDocuments = async () => {
  const dateTo = DateTime.now().toFormat('yyyy-MM-dd') + 'T00:00:00'
  const requestURL = `${baseURL}/PaymentDocuments/v4/documents?dateFrom=2020-01-01T00:00:00&dateTo=${dateTo}&type=9&showFullInfo=true`
  return Fetch.get(requestURL, getAuthHeader())
}

const getUserNumberFromDocument = async (document) => new Promise(async (resolve, reject) => {
  try {
    const { firstName, lastName, patronymicName, document_09 } = document
    const number = document_09.source.sourceUNN
    const name = `${lastName} ${firstName}${patronymicName ? ' ' + patronymicName : ''}`
    return resolve({ name, number })
  }
  catch (e) {
    console.error(e)
    return reject(e.message)
  }
})

export const getUserProperties = async () => {
  const { data: documents } = await getDocuments()
  if (!documents) return
  let user = await Promise.any(documents.map(getUserNumberFromDocument))
  user.bank = 'prior'
  return user
}

const formatAccount = ({ iban: number, rest: amount, id, currency: code }) => ({ number, type: 'Текущий', amount, id, currency: { code, ISO: get(Store.currencyISOMap).get(Number(code)) } })

export const getAccounts = async () => {
  const requestURL = `${baseURL}/account/v1/accounts?fields=rest,%20restNC,%20closed`
  const { data: accounts } = await Fetch.get(requestURL, getAuthHeader())
  return accounts ? accounts.map(formatAccount) : null
}

const formatTransaction = (transactionAccount) => (transaction) => {
  const transactionDate = DateTime.fromFormat(transaction.date, 'yyyy-MM-dd hh:mm:ss', { zone: 'Europe/Minsk' })
  const transactionUnixInteger = transactionDate.toUnixInteger()
  const transactionKind = transaction.amount.debitAmount === 0 ? 'incoming' : 'outgoing'
  const transactionAmount = {
    equivalent: transactionKind === 'incoming' ? transaction.amount.creditAmountNC : transaction.amount.debitAmountNC,
    original: transactionKind === 'incoming' ? transaction.amount.creditAmount : transaction.amount.debitAmount
  }
  return {
    hash: Utilities.generateHashFromString(`${transactionUnixInteger}.${transactionKind}.${transaction.description}.${transactionAmount.equivalent}`),
    active: false,
    special: false,
    kind: transactionKind,
    date: transactionDate,
    purpose: transaction.description,
    account: {
      number: transactionAccount.number,
      bank: {
        code: 'UNBSBY2X'
      }
    },
    amount: transactionAmount,
    correspondent: {
      name: transaction.customerName,
      number: transaction.customerTaxNumber,
      account: {
        number: transaction.iban,
        bank: {
          name: transaction.correspondentBankName,
          code: transaction.bankCode
        }
      }
    },
    currency: transactionAccount.currency,
    ...(transaction.id) && {
      document: {
        ...(transaction.id) && { id: transaction.id }
      }
    },
    ...(transaction.taxCode && { code: transaction.taxCode })
  }
}

const getStatement = async (accountId, interval) => {
  let [dateFrom, dateTo] = interval
  const today = DateTime.now()
  if (dateTo.startOf('day') > today.startOf('day')) dateTo = today
  const requestURL = `${baseURL}/account/v1/transactions?dateFrom=${dateFrom.toFormat('yyyy-MM-dd')}&dateTo=${dateTo.toFormat('yyyy-MM-dd')}&accounts=${accountId}`
  const response = await Fetch.get(requestURL, getAuthHeader())
  return response
}

const getAccountTransactionsForIntervals = async (account, intervals) => {
  let transactions = []
  for (const interval of intervals) {
    const statement = await getStatement(account.id, interval)
    if (!statement) continue
    const statementTransactions = statement.data?.[0]?.payments
    if (!statementTransactions) continue
    const formattedStatementTransactions = statementTransactions.map(formatTransaction(account))
    transactions = [...transactions, ...formattedStatementTransactions]
  }
  return transactions
}

const sortByDate = (a, b) => a.date < b.date ? -1 : a.date > b.date ? 1 : 0

export const getTransactionsFromAccounts = async (accounts) => {
  const intervals = Time.generatePeriodIntervals()
  let transactions = []
  for (const account of accounts) {
    const accountTransactions = await getAccountTransactionsForIntervals(account, intervals)
    transactions = [...transactions, ...accountTransactions]
  }
  return transactions.sort(sortByDate)
}