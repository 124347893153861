<script>
  import { user, rate, activities, inspections } from '../modules/store.module'
  import Input from './controls/input.svelte'
  import Select from './controls/select.svelte'
  
  const { hostname } = window.location 
  const isProduction = hostname.includes('bittax.by')
  let userDataIsMissing = false

  const setActivityName = (code) => $user.activity.name = $activities?.find(activity => activity.code == code)?.name
  $: setActivityName($user.activity.code)

  const setInspectionName = (code) => {
    $user.inspection ??= {}
    $user.inspection.name = $inspections?.find(inspection => inspection.code == code)?.name
  }
  $: setInspectionName($user?.inspection?.code)

</script>

<section class="questionnaire">
    <Input bind:value={$user.name} required={true} validation={['isNotEmpty']}>
      <svelte:fragment slot="label">ФИО</svelte:fragment>
    </Input>
    <Select bind:value={$rate} enabled={!isProduction || $user?.type?.code === 2} required={true}>
      <svelte:fragment slot="label">Вид налога</svelte:fragment>
      <svelte:fragment slot="options">
        {#if $rate && (!isProduction || $user.type.code === 1)}
          <option value='УСН'>УСН 6%</option>
        {/if}
        {#if $rate && (!isProduction || $user.type.code === 2)}
          <option value='ОСН'>Доходы минус 20% расходов</option>
          <option value='ОСНР'>Доходы минус расходы</option>
        {/if}
      </svelte:fragment>
    </Select>
    <Input bind:value={$user.address} required={true} validation={['isNotEmpty']}> 
      <svelte:fragment slot="label">Адрес</svelte:fragment>
    </Input>
    <Input bind:value={$user.number} enabled={userDataIsMissing} required={true} validation={['isUserNumber']} type={'integer'} limit={9}> 
      <svelte:fragment slot="label">УНП</svelte:fragment>
    </Input>
    <Input bind:value={$user.inspection.code} required={true} validation={['isNotEmpty', 'isValidInspectionCode']} type={'integer'} limit={3}> 
      <svelte:fragment slot="label">Код ИМНС</svelte:fragment>
    </Input>
    <Input bind:value={$user.activity.code} required={true} validation={['isNotEmpty', 'isValidActivityCode']} type={'number'} limit={5}> 
      <svelte:fragment slot="label">Код ОКЭД</svelte:fragment>
    </Input>
    <Input bind:value={$user.phone} required={true} validation={['isNotEmpty']}> 
      <svelte:fragment slot="label">Телефон</svelte:fragment>
    </Input>
    <Input bind:value={$user.email} required={true} validation={['isNotEmpty', 'isValidEmail']}> 
      <svelte:fragment slot="label">Email</svelte:fragment>
    </Input>
</section>

<style lang="scss">
  .questionnaire {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 8px;
    & > :global(*) {
      &:nth-child(1) {
        grid-column: span 3;
        @media (max-width: 1023px) {
          grid-column: span 6;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(2) {
        grid-column: span 3;
        @media (max-width: 1023px) {
          grid-column: span 6;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(3) {
        grid-column: span 6;
        @media (max-width: 1023px) {
          grid-column: span 12;
        }
      }
      &:nth-child(4) {
        grid-column: span 2;
        @media (max-width: 1023px) {
          grid-column: span 4;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(5) {
        grid-column: span 2;
        @media (max-width: 1023px) {
          grid-column: span 4;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(6) {
        grid-column: span 2;
        @media (max-width: 1023px) {
          grid-column: span 4;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(7) {
        grid-column: span 3;
        @media (max-width: 1023px) {
          grid-column: span 6;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
      &:nth-child(8) {
        grid-column: span 3;
        @media (max-width: 1023px) {
          grid-column: span 6;
        }
        @media (max-width: 639px) {
          grid-column: span 12;
        }
      }
    }
  }
</style>