<script>
  import { onMount } from 'svelte'
  import * as API from '../../modules/api.module'
  import Header from '../header.svelte'
  import Button from '../controls/button.svelte'
  import BigNumber from '../../modules/bignumber.module'

  let declaration
  let userName
  $: hasIncomeTax = BigNumber(declaration?.quarter?.totalIncomeTax).gt(0)
  $: declarationType = declaration?.rate?.startsWith('ОСН') ? 'osn': 'usn'

  onMount(async () => {
    const declarationID = window.location.pathname.replace('/declarations/', '')
    declaration = await API.getDeclaration(declarationID)
    userName = declaration.user.name.split(' ').slice(1).join(' ')
	})

  const goHome = () => window.location = '/'
</script>

<div class="main">
  <div class="container">
    <Header />
  </div>
  <div class="container container--type_small">
    {#if declaration}
      <div class="declaration">
        <h1 class="declaration__heading">Ваша декларация готова</h1>
        <p class="declaration__text">{userName}, ваша декларация доступна для скачивания по следующим ссылкам:</p>
        <h2 class="declaration__subheading">Декларация {declaration.rate === 'УСН' ? 'УСН' : 'по подоходному налогу (ОСН)'}</h2>
        <div class="declaration__list">
          <a class="declaration__link" href="/declarations/{declaration._id}/{declarationType}.pdf">Декларация {declaration.rate.replace('Р', '')} для печати и подачи в ИМНС (PDF)</a>
          <a class="declaration__link" href="/declarations/{declaration._id}/{declarationType}.xml">Декларация {declaration.rate.replace('Р', '')} для подачи через личный кабинет на портале МНС или АРМ Плательщика (XML)</a>
        </div>
        {#if hasIncomeTax}
          <h2 class="declaration__subheading">Декларация агента по подоходному налогу (АПН)</h2>
          <div class="declaration__list">
            <a class="declaration__link" href="/declarations/{declaration._id}/apn.pdf">Декларация АПН для печати и подачи в ИМНС (PDF)</a>
            <a class="declaration__link" href="/declarations/{declaration._id}/apn.xml">Декларация АПН для подачи через личный кабинет на портале МНС или АРМ Плательщика (XML)</a>
          </div>
        {/if}
        {#if declaration.rate === 'ОСН' && declaration.quarter.number === 1}
          <h2 class="declaration__subheading">Решение ИП о порядке определения расходов</h2>
          <div class="declaration__list">
            <a class="declaration__link" href="/declarations/{declaration._id}/decision.pdf">Решение о порядке определения расходов (PDF)</a>
          </div>
        {/if}
        <p class="declaration__text">Декларацию в налоговую вы обязаны подать до {declaration.quarter.submissionDate}. Если вы не оплатили налог с помощью нашего сервиса, не забудьте оплатить его до {declaration.quarter.paymentDate}.</p>
        <p class="declaration__text">Вы очень поможете нам, если пройдете <a class="declaration__link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSftB48zl7mQfQvBRqx3vz7FMAmlMHd74Eki_9cJw6vnoHKM9A/viewform?usp=share_a">краткий опрос об удобстве пользования нашим сервисом</a>.</p>  
        <Button on:click={goHome}>Вернуться в BitTax</Button>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .main {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    row-gap: 32px;
  }
  .container {
    justify-self: center;
    padding: 0 16px;
    box-sizing: border-box;
    max-width: 1280px;
    width: 100%;
    &--type_small {
      max-width: 768px;
    }
  }
  .declaration {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    &__heading {
      font-size: 24px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
    }
    &__text {
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }
    &__subheading {
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
    }
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__link {
      cursor: pointer;
      color: #EF3124;
      text-decoration: underline;
      text-decoration-color: #EF3124;
      transition: text-decoration-color .3s ease-in-out;
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }
</style>