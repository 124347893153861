<script>
  import { bank } from '../modules/store.module'
  import { Router, Route } from 'svelte-routing'
  import Home from './pages/home.svelte'
  import Landing from './pages/landing.svelte'
	import Declarations from './pages/declarations.svelte'
  import Token from './pages/token.svelte'
  import Denial from './pages/denial.svelte'
  import Error from './pages/error.svelte'
  import Instructions from './pages/instructions.svelte'
  import Requirements from './pages/requirements.svelte'
  import Tooltip from './controls/tooltip.svelte'
</script>

<Router>
  <Tooltip />
  {#if $bank.startsWith('bittax')}
    <Route path="/" component="{Landing}" />
  {:else}
    <Route path="/" component="{Home}" />
  {/if}
  <Route path="/auth/token" component="{Token}" />
  <Route path="/auth/denial" component="{Denial}" />
  <Route path="/declarations/:id" component="{Declarations}" />
  <Route path="/instructions" component="{Instructions}" />
  <Route path="/requirements" component="{Requirements}" />
  <Route path="*" component="{Error}" />
</Router>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  .root,
  #root,
  #docs-root {
    --background: #EF3124;
    --foreground: #FFFFFF;
    --outline: #EF3124;
    --accent: #EF3124;
  }

  .alfa {
    --background: #EF3124;
    --foreground: #FFFFFF;
    --outline: #EF3124;
    --accent: #EF3124;
  }

  .bsb {
    --background: #DE0B0B;
    --foreground: #FFFFFF;
    --outline: #DE0B0B;
    --accent: #DE0B0B;
  }

  .dabrabyt {
    --background: #E31E24;
    --foreground: #FFFFFF;
    --outline: #E31E24;
    --accent: #E31E24;
  }

  .prior {
    --background: #FFEC00;
    --foreground: #000000;
    --outline: #000000;
    --accent: #FFEC00;
  }
</style>