<script>
  import BigNumber from '../modules/bignumber.module'
  import Transactions from './transactions.svelte'
  import Navigation from './navigation.svelte'
  import Input from './controls/input.svelte'

  export let transactions
  export let quarter 

  let selectedRevenue
  $: quarter.revenue = BigNumber(selectedRevenue).plus(BigNumber(quarter.unaccountedRevenue || 0)).toFormat(2)
</script>

<section class="revenue">
  <Transactions {transactions} bind:selectedTransactionSum={selectedRevenue} />
  <Navigation>
    <Input value={selectedRevenue} enabled={false} postfix={'BYN'}>
      <span slot="label">Выбранный доход</span>
      <svelte:fragment slot="tooltip">Сумма отмеченных налогооблагаемых поступлений в выбранном квартале.</svelte:fragment>
    </Input>
    <Input bind:value={quarter.unaccountedRevenue} type={'float'} postfix={'BYN'}>
      <span slot="label">Неучтенный доход</span>
      <svelte:fragment slot="tooltip">Сумма денежных средств, полученных вами не на расчетный счет в банке, а также сумма комиссий, уплаченных вами за выбранный квартал.</svelte:fragment>
    </Input>
    <Input value={quarter.revenue} enabled={false} postfix={'BYN'}>
      <span slot="label">Итого дохода</span>
    </Input>
  </Navigation>
</section>

<style lang="scss">
  .revenue {
    display: grid;
    grid-template-rows: max-content  1fr max-content;
  }
</style>