<script>
  export let value
  export let required = false
  export let onChange = null
  export let enabled = true
  $: loading = !value
</script>

<div class="select {$$restProps.class || ''}" class:select--loading={loading} class:select--disabled={!enabled} >
  <div class="select__header">
    <label class="select__label" class:select__label--required={required}>
      <slot name="label"></slot>
    </label>
  </div>
  <select class="select__field" class:select__field--loading={loading} class:select__field--disabled={!enabled} bind:value on:change={onChange}>
    {#if loading}
      <option selected disabled></option>
    {/if}
    <slot name="options"></slot>
  </select>
</div>


<style lang="scss">
  .select {
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 84px;
    gap: 4px;
    &:not(.select--loading):after {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: #ef3124;
      position: absolute;
      right: 8px;
      top: 34px;
      opacity: 1;
      transform: scale(1);
      animation: pulse 1s infinite;
      z-index: -1;
    }
    &--disabled:not(.select--loading):after {
      display: none;
    }
    &--loading {
      &:before {
        display: block;
        content: 'Загрузка…';
        color: #A8A29F;
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 34px;
        font-size: 14px;
        line-height: 14px;
      }
      &:after {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        border: 2px solid #D1D5DB;
        animation: spin 1s linear infinite;
        border-radius: 8px;
        border-top-color: #EF3124;
        position: absolute;
        right: 12px;
        top: 34px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    &__label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      &--required:after {
        content: '*';
        color: #EF3124;
      }
    }
    &__field {
      cursor: pointer;
      -webkit-appearance: none;
      background-color: transparent;
      width: 100%;
      box-sizing: border-box;
      text-indent: 12px;
      font-size: 14px;
      line-height: 16px;
      height: 44px;
      border-radius: 2px;
      border: 1px solid #E7E5E4;
      &:not(&--loading) {
        background-image: url('/images/dropdown.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
      }
      &:focus {
        outline: none;
        text-indent: 11px;
        border: 2px solid black;
      }
      &--disabled {
        user-select: none;
        pointer-events: none;
        background-color: #E7E5E4;
        border-color: transparent;
        background-image: none;
      }
      &--loading {
        color: transparent;
        user-select: none;
        pointer-events: none;
        background-color: #E7E5E4;
        border-color: transparent;
      }
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.5);
      opacity: .3;
    }

    70% {
      transform: scale(1.5);
      opacity: 0;
    }

    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }
</style>