<div class="prior-logo">
  <svg class="logotype" width="196" height="48" viewBox="0 0 196 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1288 32.0249L26.663 24.1473L29.6736 14.6048H26.663H5.54968L0 32.0249H9.05138L12.8478 19.7665H18.9721L15.0873 32.0249H24.1288Z" fill="#060A0E"/>
    <path d="M47.1822 26.1855C47.3786 25.7975 48.2528 23.0767 46.8924 21.3283C46.1164 20.2527 44.6578 19.575 42.0254 19.575C40.1813 19.5073 38.3672 20.055 36.8686 21.1318C36.4705 21.4113 36.1106 21.7415 35.798 22.1141L36.5788 19.8795H29.0892L26.6827 27.4624L23.6672 37H31.594L33.7352 30.4779C34.1232 31.0623 34.801 31.9513 36.849 32.2312C37.2666 32.3087 37.6916 32.34 38.1161 32.3245C43.5626 32.3245 45.9003 29.1126 46.8728 27.0695C47.006 26.7863 47.1097 26.49 47.1822 26.1855ZM38.9117 26.1855C38.7152 26.7699 38.229 28.15 36.8686 28.5183C36.6261 28.6409 36.3594 28.708 36.0877 28.7148C34.1232 28.7148 34.6144 26.8681 34.9238 25.9841C35.2184 25.3015 35.6015 23.946 36.8883 23.5531C37.1829 23.5531 37.3794 23.4598 37.6741 23.4598C39.7122 23.4598 39.1474 25.3015 38.9313 26.1855H38.9117Z" fill="#060A0E"/>
    <path d="M172.684 19.86H171.323H164.708L163.342 24.1475H160.518L161.884 19.86H153.81L150.603 29.7856L149.92 32.0251H150.603H157.994L159.35 27.6443H162.174L160.813 32.0251H168.789L171.323 24.1475L172.684 19.86Z" fill="#060A0E"/>
    <path d="M195.643 19.8598H186.499L180.271 24.7268L183.582 14.5066H175.503L171.323 27.7522L169.958 32.0249H171.323H177.938L179.883 26.0922L182.516 32.0249H191.945L188.247 25.2131L195.643 19.8598Z" fill="#060A0E"/>
    <path d="M151.182 24.6237C151.28 24.2406 151.28 23.9509 151.379 23.6415C151.453 23.2325 151.421 22.8112 151.284 22.4186C151.147 22.0261 150.911 21.6757 150.598 21.402C149.615 20.3313 147.386 19.5554 142.617 19.5554H140.77C138.973 19.6496 137.186 19.8761 135.422 20.2331L134.351 23.6415C136.281 23.0658 138.276 22.7357 140.289 22.6592H140.78C143.796 22.7525 144.085 23.5285 143.992 24.1326C143.997 24.1855 143.991 24.239 143.973 24.2891C143.955 24.3392 143.927 24.3847 143.889 24.4224C142.89 24.2981 141.885 24.2325 140.878 24.2259H140.78C134.749 24.2259 132.22 26.1904 131.149 27.9241C130.979 28.297 130.848 28.6873 130.761 29.088C130.674 29.4052 130.663 29.7388 130.731 30.0609C130.798 30.383 130.942 30.6843 131.149 30.9396C131.925 31.8089 133.679 32.3 135.82 32.3C137.545 32.3329 139.25 31.9327 140.78 31.136C141.271 30.7431 141.654 30.4484 141.944 30.2569L141.365 32.0053H148.854L150.608 26.269L151.182 24.6237ZM143.113 27.0793C142.879 27.6999 142.471 28.2402 141.939 28.6362C141.57 28.8419 141.18 29.0066 140.775 29.1273C140.491 29.2044 140.196 29.2359 139.901 29.2206C138.83 29.2206 138.251 28.9358 138.344 28.3513V28.0567C138.487 27.7489 138.704 27.4814 138.976 27.2782C139.247 27.075 139.565 26.9426 139.901 26.8927C140.191 26.8927 140.392 26.7945 140.775 26.7945C141.322 26.7024 141.877 26.6695 142.43 26.6963H143.211L143.113 27.0793Z" fill="#060A0E"/>
    <path d="M131.527 25.1148C131.775 24.1575 131.636 23.1413 131.139 22.286C130.359 20.8126 128.222 19.6585 123.846 19.6585C122.998 19.6661 122.151 19.7317 121.312 19.8549C120.05 20.1447 119.082 20.7292 117.712 21.7065C117.909 20.3461 119.367 19.1772 120.629 18.691C120.835 18.5687 121.068 18.5011 121.307 18.4945C122.525 18.1853 123.762 17.9572 125.01 17.8119C126.159 17.7279 127.298 17.5322 128.409 17.2274L128.507 17.0359L130.472 11C130.177 11 129.882 11.1031 129.597 11.1031C128.232 11.2947 126.872 11.4911 125.413 11.6876C124.017 11.9052 122.646 12.2639 121.322 12.7582C121.13 12.7582 121.032 12.8565 120.831 12.8565C119.205 13.5943 117.723 14.6154 116.455 15.872C114.909 17.671 113.661 19.7053 112.757 21.898C111.971 23.431 111.413 25.0707 111.102 26.765C111.003 26.9566 109.447 32.3049 119.176 32.3049C119.889 32.3225 120.603 32.2913 121.312 32.2116C127.928 31.7204 130.26 28.5134 131.135 26.2788C131.311 25.9079 131.443 25.5171 131.527 25.1148V25.1148ZM122.943 25.7975C122.653 26.6717 122.26 28.1303 121.288 28.6166C120.99 28.7864 120.645 28.8553 120.305 28.813C118.748 28.813 119.136 27.1579 119.623 25.6993C119.724 25.218 119.924 24.7627 120.209 24.3614C120.493 23.9602 120.857 23.6216 121.278 23.3665C121.52 23.2437 121.787 23.1766 122.059 23.17C123.748 23.1651 123.355 24.6237 122.943 25.7975Z" fill="#060A0E"/>
    <path d="M105.169 19.5701C103.338 19.5121 101.538 20.0517 100.042 21.1073V21.1073C99.6638 21.4111 99.3061 21.7392 98.9709 22.0896L99.7469 19.855H92.2229L88.4315 31.9218L86.7764 36.9853H94.8504L96.9131 30.4632C97.3011 31.0476 98.082 31.9366 100.027 32.2165C100.476 32.2976 100.932 32.3289 101.387 32.3098C107.713 32.3098 109.948 27.8357 110.434 26.1806C110.9 24.4371 112.069 19.5701 105.169 19.5701ZM102.05 26.1855C101.859 26.8682 101.373 28.2237 100.012 28.5184C99.7687 28.6386 99.5025 28.7055 99.2312 28.7148C97.2667 28.7148 97.7579 26.8682 98.0624 25.9841C98.2539 25.3015 98.8383 24.0197 100.027 23.6562C100.306 23.5433 100.601 23.4769 100.901 23.4598C102.836 23.4598 102.345 25.3064 102.05 26.1855V26.1855Z" fill="#060A0E"/>
    <path d="M88.4314 28.1304C89.0986 27.2574 89.5982 26.2683 89.9047 25.2131C90.1521 24.4506 90.1419 23.6279 89.8757 22.8718C89.6094 22.1156 89.1019 21.468 88.4314 21.0288C87.1643 20.1497 84.9296 19.5554 81.3346 19.5554C80.6506 19.5349 79.966 19.5661 79.2866 19.6487C72.5779 20.1398 70.2402 22.9589 69.366 24.7073C69.2963 25.0426 69.1977 25.3712 69.0713 25.6895C68.8798 26.4704 68.3887 28.317 69.366 29.7757C70.3482 31.141 72.4797 32.3049 77.2239 32.3049C77.9063 32.3206 78.5889 32.2894 79.267 32.2116C84.3501 31.8236 86.9678 29.977 88.4314 28.1304ZM81.1333 25.6748C80.8435 26.5539 80.4555 28.0076 79.2866 28.592C78.9739 28.7167 78.641 28.7832 78.3044 28.7885C76.4627 28.7885 77.2338 26.7503 77.6316 25.6748C77.7119 25.1741 77.9009 24.6971 78.1851 24.2771C78.4693 23.8572 78.8419 23.5044 79.2768 23.2437C79.5562 23.1337 79.8513 23.0691 80.151 23.0522C81.9142 23.0767 81.423 24.7269 81.1333 25.6944V25.6748Z" fill="#060A0E"/>
    <path d="M70.0487 19.86H69.3661H60.3147L54.5784 27.0598L57.2059 19.86H50.006L46.8923 29.5891L46.1163 32.0251H46.8923H55.649L61.6849 24.6239L58.9592 32.0251H66.1541L69.3661 21.8097L70.0487 19.86Z" fill="#060A0E"/>
  </svg>
</div>

<style lang="scss">
  .prior-logo {
    display: flex;
  }
  .logotype {
    @media (max-width: 520px) {
      display: none;
    }
  }
</style>