<script>
  import { tooltip } from '../../modules/store.module'

  const closeTooltip = () => $tooltip = undefined
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="tooltip" class:tooltip--open={Boolean($tooltip)} on:click|self={closeTooltip}>
  <div class="tooltip__card">
    <header class="tooltip__header">
      <span class="tooltip__heading">
        {$tooltip?.heading}
      </span>
      <button class="tooltip__close" on:click={closeTooltip}>×</button>
    </header>
    <div class="tooltip__contents">
      {@html $tooltip?.contents}
    </div>
  </div>
</div>

<style lang="scss">
  .tooltip {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0; 
    left: 0; 
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 3;
    &--open {
      display: flex;
    }
    &__card {
      background-color: white;
      max-width: 320px;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      border-radius: 4px;
    }
    &__header {
      display: flex;
      padding: 16px;
      column-gap: 16px;
      border-bottom: 1px solid #D1D5DB;
      justify-content: space-between;
      font-weight: 500;
    }
    &__close {
      font-size: 24px;
      color: #A8A29F;
      cursor: pointer;
      user-select: none;
      transition: opacity .3s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
    &__contents {
      padding: 16px 16px 24px 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      font-weight: 400;
    }
  }
</style>