<script>
  import { tooltip } from '../../modules/store.module'
  import { mask } from '../../modules/masks.module'
  import { validate } from '../../modules/validation.module'
  export let value
  export let enabled = true
  export let required = false
  export let postfix = null
  export let type = null
  export let limit = null
  export let toggleProperty = null
  export let editable = Boolean(toggleProperty)
  export let customProperties = null
  export let property = null
  export let validation = []
  export let tooltipVisible = true

  const inputMode = type === 'float' ? 'decimal' : type === 'integer' ? 'numeric' : null
  const handleBlur = ({ target }) => value = target.value
  $: loading = value === undefined || value === 'NaN'
  $: if (editable) enabled = customProperties?.includes(property)

  const openTooltip = ({ target }) => {
    const contents = target.querySelector('.input__tooltip-contents').innerHTML
    const heading = target.parentNode.querySelector('.input__label').innerText
    $tooltip = { heading, contents }
  }
</script>

<div class="input {$$restProps.class || ''}">
  <div class="input__header">
    <label class="input__label" class:input__label--required={required}>
      <slot name="label" />
    </label>
    {#if $$slots.tooltip && tooltipVisible}
      <button class="input__tooltip" on:click={openTooltip}>
        <div class="input__tooltip-contents">
          <slot name="tooltip" />
        </div>
      </button>
    {/if}
  </div>
  <div class="input__group {editable && 'input__group--editable'} {loading && 'input__group--loading'}" data-postfix={postfix}>
    <input class="input__field {editable && 'input__field--editable'}" inputmode={inputMode} class:input__field--disabled={!enabled} disabled={!enabled} class:input__field--loading={loading} bind:value use:mask={[type, limit]} on:blur={handleBlur} use:validate={validation}>
    {#if editable}
      <button class="input__button {enabled && 'input__button--type_reset'}" on:click={() => toggleProperty(property)}></button>
    {/if}
  </div>
  <div class="input__error"></div>
</div>

<style lang="scss">
  .input {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &:has(.input__field:not([disabled]):not(:valid)) {
      animation: shake .3s linear;
    }
    &__header {
      display: flex;
      column-gap: 4px;
      align-items: center;
    }
    &__label {
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      white-space: nowrap;
      &--required:after {
        content: '*';
        color: #EF3124;
      }
    }
    &__tooltip {
      &:before {
        content: '?';
        user-select: none;
        width: 14px;
        height: 14px;
        color: white;
        display: flex;
        background-color: #A8A29F;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: opacity .3s ease-in-out;
        &:hover {
          opacity: .7;
        }
      }
      &-contents {
        display: none;
      }
    }
    &__group {
      display: flex;
      position: relative;
      &[data-postfix]:after {
        position: absolute;
        right: 12px;
        top: 12px;
        content: attr(data-postfix);
        font-size: 14px;
        color: #A8A29F;
        pointer-events: none;
      }
      &--editable[data-postfix]:after {
        right: 56px;
      }
      &--loading[data-postfix]:after {
        content: '';
        top: 14px;
      }
      &--loading {
        &:before {
          display: block;
          content: 'Загрузка…';
          color: #A8A29F;
          position: absolute;
          // z-index: 1;
          left: 12px;
          top: 14px;
          font-size: 14px;
          line-height: 14px;
        }
        &:after {
          content: '';
          display: block;
          height: 16px;
          width: 16px;
          border: 2px solid #D1D5DB;
          animation: spin 1s linear infinite;
          border-radius: 8px;
          border-top-color: #EF3124;
          position: absolute;
          right: 12px;
          top: 14px;
        }
      }
    }

    &__button {
      max-width: 44px;
      width: 100%;
      display: flex;
      background-color: white;
      border: 1px solid #E7E5E4;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background-image: url('/images/pencil.svg');
      background-repeat: no-repeat;
      background-position: center;
      &--type {
        &_reset {
          background-image: url('/images/reset.svg');
        }
      }
    }
    &__error {
      font-size: 14px;
      line-height: 16px;
      color: #EF3124;
      min-height: 16px;
    }
    &__field {
      -moz-appearance: textfield;
      width: 100%;
      box-sizing: border-box;
      text-indent: 12px;
      font-size: 14px;
      line-height: 16px;
      height: 44px;
      border-radius: 2px;
      border: 1px solid #E7E5E4;
      
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        outline: none;
        text-indent: 11px;
        border: 2px solid black;
      }
      &:not([disabled]):not(:valid) {
        border-color: #EF3124;
      }
      &--editable {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
      &--disabled {
        user-select: none;
        pointer-events: none;
        background-color: #E7E5E4;
        border-color: transparent;
        opacity: 1;
      }
      &--loading {
        color: transparent;
        user-select: none;
        pointer-events: none;
        background-color: #E7E5E4;
        border-color: transparent;
      }
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes shake {
    8%, 41% {
        -webkit-transform: translateX(-10px);
    }
    25%, 58% {
        -webkit-transform: translateX(10px);
    }
    75% {
        -webkit-transform: translateX(-5px);
    }
    92% {
        -webkit-transform: translateX(5px);
    }
    0%, 100% {
        -webkit-transform: translateX(0);
    }
}
@keyframes shake {
    8%, 41% {
        -webkit-transform: translateX(-10px);
    }
    25%, 58% {
        -webkit-transform: translateX(10px);
    }
    75% {
        -webkit-transform: translateX(-5px);
    }
    92% {
        -webkit-transform: translateX(5px);
    }
    0%, 100% {
        -webkit-transform: translateX(0);
    }
}
</style>